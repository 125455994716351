<template>
    <div>
        <router-view name="topbar"></router-view>

        <v-main>
            <v-container fluid>
                <v-container class="main-body">
                    <transition name="fade-transform" mode="out-in">
                        <router-view name="main"></router-view>
                    </transition>
                </v-container>
            </v-container>
            <router-view name="newProject"></router-view>
        </v-main>
        <chat-box></chat-box>
        <the-footer class="mt-10"></the-footer>
    </div>
</template>

<script>
import ChatBox from "../components/ChatBox/ChatBox.vue";
import ChatBoxHero from "../components/ChatBox/ChatBoxHero.vue";
import NewProjectBtn from "./components/NewProjectBtn.vue";
import TheFooter from "./TheFooter.vue";
export default {
    name: "Layout",
    components: { NewProjectBtn, ChatBoxHero, ChatBox, TheFooter },

    computed: {
        currentRouteName() {
            return this.$route.name;
        },
    },
};
</script>

<style lang="scss" scoped>
.test {
    position: relative;
}

.main-body {
    border-radius: 0 0 50px 50px;
    min-height: 90vh;
}

.app__container {
    background-color: var(--v-primary-base);
}
</style>
