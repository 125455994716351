export const getDevice = () => {
    if (
        /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        )
    ) {
        return true;
    }

    if (window.matchMedia("only screen and (max-width: 900px)").matches) {
        return true;
    }

    return false;
};

export const getRole = (role) => {
    switch (role) {
        case "user":
            return "user";
        case "admin":
            return "admin";
        case "chef":
            return "chef";
        default:
            return "user";
    }
};

export const blobToBase64 = (blob) => {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
};
