<template>
    <v-fab-transition v-if="!mobile">
        <v-btn
            light
            fixed
            top
            right
            fab
            color="primary lighten-3"
            style="z-index: 100; top: 90px; right: 2%; box-shadow: 0px 0px 20px rgba(10, 52, 67, 0.25) !important;"
            @click="createNew()"
        >
            <v-icon color="primary" size="28">
                fa-solid fa-plus
            </v-icon>
        </v-btn>
    </v-fab-transition>
</template>

<script>
import { mapGetters } from "vuex";
import { getDevice } from "@/helpers/generalHelpers.js";
export default {
    name: "NewProjectBtn",
    data() {
        return {
            mobile: false,
        };
    },

    computed: {
        ...mapGetters({
            isLoggedIn: "auth/isLoggedIn",
        }),
    },

    methods: {
        createNew() {
            this.$router.push({ name: "NewProject" });

            // this.$router.push({ name: "Register" });
        },
        isMobile() {
            this.mobile = getDevice();
        },
    },

    mounted() {
        window.addEventListener("resize", this.isMobile);
        this.isMobile();
    },

    beforeDestroy() {
        window.removeEventListener("resize", this.isMobile);
    },
};
</script>

<style lang="scss" scoped></style>
