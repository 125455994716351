<template>
    <router-link :to="{name: 'Home'}">
        <v-avatar size="78" rounded> 
            <v-img
                alt="App Logo"
                class="shrink"
                contain
                :src="logo"
                transition="scale-transition"
            />
        </v-avatar>
    </router-link>
</template>

<script>
import logo from "../../assets/app_logo.png"
export default {
    name:   "AppLogo",

    data() {
        return {
            logo
        }
    }
}
</script>