<template>
    <ul class="d-sm-flex align-center menu__items">
        <router-link
            v-for="(item, index) in listMenu"
            :key="index"
            :to="item.path"
            class="link-btn"
            tag="li"
            active-class="active"
        >
            <a>{{ $t(`menu.links.${item.label}`) }}</a>
        </router-link>
    </ul>
</template>

<script>
export default {
    name: "NavItems",

    data() {
        return {
            listMenu: [
                {
                    name: "Home",
                    path: "/home",
                    label: "home",
                },
                {
                    name: "Favourites",
                    path: "/favourites",
                    label: "favourites",
                },
                {
                    name: "MyProjects",
                    path: "/my-projects",
                    label: "myProjects",
                },
                {
                    name: "NewProject",
                    path: "/new-project",
                    label: "newProject",
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.menu__items {
    list-style-type: none;
    padding: 0;

    li a {
        text-decoration: none;
        color: white;
    }

    .link-btn {
        font-size: 0.965em;
        font-weight: 700;
        padding: 0.46em;
        margin: 0.06em;
        box-shadow: none;
    }
}

.active {
    border-bottom: 1px solid white;
}

.active a {
    color: var(--v-accent-darken4);
}

@media (max-width: 900px) {
    .menu__items {
        display: block !important;
        padding: 10px;
    }
}
</style>
