const setLogin = ({ commit }, access_token) => {
    localStorage.setItem("lgf_token", `Bearer ${access_token}`);
    commit("setLogin", `Bearer ${access_token}`);
};

const setUser = ({ commit }, user) => {
    localStorage.setItem("lgf_user_profile", JSON.stringify(user));
    commit("setUser", user);
};

const setLogout = ({ commit }) => {
    localStorage.removeItem("lgf_token");
    localStorage.removeItem("lgf_user_profile");
    commit("logout");
};

const setHero = ({ commit }, hero) => {
    localStorage.setItem("lgf_hero", hero);
    commit("setHero", hero);
};

export default {
    setLogin,
    setLogout,
    setUser,
    setHero,
};
