import { render, staticRenderFns } from "./NewProjectBtn.vue?vue&type=template&id=4e387dbd&scoped=true&"
import script from "./NewProjectBtn.vue?vue&type=script&lang=js&"
export * from "./NewProjectBtn.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e387dbd",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFabTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VFabTransition,VIcon})
