<template>
    <v-menu
        offset-y
        content-class="profile__menu"
        nudge-bottom="6"
        nudge-left="40"
        min-width="200"
        transition="slide-y-transition"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                depressed
                dark
                icon
                width="60"
                height="60"
                color="primary lighten-3"
                v-bind="attrs"
                v-on="on"
            >
                <avatar v-if="isLoggedIn" size="50"></avatar>
                <v-icon v-else size="18" color="accent darken-4">
                    fa-solid fa-user
                </v-icon>
            </v-btn>
        </template>
        <v-card flat color="appBackground">
            <v-card-title v-if="isLoggedIn" class="pa-2 d-flex justify-center">
                <span class="action-card-title">{{ userName }} </span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pa-1">
                <v-list color="appBackground">
                    <v-list-item
                        v-for="(item, index) in listItems"
                        :key="index"
                        class="profile-list-item"
                        @click="profileAction(item)"
                    >
                        <v-list-item-title class="profile-items">
                            <v-icon size="16" :color="item.color" class="mr-1">
                                {{ item.icon }}
                            </v-icon>
                            {{ $t(`menu.profile.${item.label}`) }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-card-text>
        </v-card>
    </v-menu>
</template>

<script>
import { withAsync } from "@/helpers/withAsync";
import { apiStatus } from "@/api/constants/apiStatus";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus";
import { logoutUser } from "@/api/authApi.js";
import { apiObject } from "@/api/api.js";
import { mapState } from "vuex";
import profile from "../../assets/profile.png";
import Avatar from "@/components/General/Avatar.vue";

export default {
    name: "NavItems",

    props: {
        isLoggedIn: {
            required: true,
            type: Boolean,
        },
    },
    components: {
        Avatar,
    },

    data() {
        return {
            profile,
            loggedInItems: [
                {
                    name: "Profile",
                    label: "profile",
                    icon: "fa-regular fa-address-card",
                    color: "text lighten-2",
                },
                {
                    name: "Library",
                    label: "library",
                    icon: "fas fa-book-open",
                    color: "text lighten-2",
                },
                // {
                //     name: "Favourites",
                //     label: "favourites",
                //     icon: "fa-solid fa-heart",
                //     color: "text lighten-2",
                // },
                // {
                //     name: "MyProjects",
                //     label: "myProjects",
                //     icon: "fa-solid fa-border-all",
                //     color: "text lighten-2",
                // },
                {
                    name: "LogOut",
                    label: "logout",
                    icon: "fa-solid fa-arrow-right-from-bracket",
                    color: "text lighten-2",
                },
            ],
            items: [
                {
                    name: "Login",
                    label: "login",
                    icon: "fa-solid fa-arrow-right-to-bracket",
                    color: "text lighten-2",
                },
                {
                    name: "Register",
                    label: "register",
                    icon: "fa-solid fa-person-walking-arrow-right",
                    color: "text lighten-2",
                },
            ],
        };
    },

    computed: {
        listItems() {
            if (this.isLoggedIn) {
                return this.loggedInItems;
            }

            return this.items;
        },
        ...mapState({
            userName: (state) => state.auth.user.name,
        }),
    },

    methods: {
        async logout() {
            const { response, error } = await withAsync(logoutUser);

            if (error) {
                return;
            }

            this.$store.dispatch("auth/setLogout");
            this.$store.dispatch("auth/setHero", "");
            this.$router.push({ name: "Landing" });
        },

        profileAction(item) {
            if (item.name == "LogOut") {
                this.logout();
                return;
            }
            this.$router.push({
                name: item.name,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.action-card-title {
    font-size: 0.8em;
    color: var(--v-text-lighten2);
}
.profile-items {
    font-size: 0.967em;
    color: var(--v-text-lighten2);
}

.profile__menu {
    z-index: 200 !important;
}

.profile__menu .profile-list-item:hover {
    background-color: var(--v-primary-lighten5);
}
</style>
