<template>
    <v-menu
        offset-y
        top
        rounded="t-xl"
        nudge-top="18"
        min-width="350"
        max-width="400"
        z-index="200"
        :close-on-content-click="false"
        content-class="chatbox"
        transition="slide-y-reverse-transition"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-fab-transition>
                <v-btn
                    icon
                    color="transparent"
                    light
                    fixed
                    bottom
                    right
                    depressed
                    :ripple="false"
                    style="z-index: 100; right: 1%; bottom: 80px;"
                    v-bind="attrs"
                    v-on="on"
                >
                    <hero :selected-hero="hero" animation-is="sayHi"></hero>
                </v-btn>
            </v-fab-transition>
        </template>

        <v-card flat height="600" color="primary lighten-5">
            <v-card-title>
                <span class="welcome-txt">{{ $t(`chatbox.welcomeTxt`) }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pt-1">
                <v-container class="fill-height list-chat pt-0">
                    <v-row class="fill-height pb-14">
                        <v-col class="py-1">
                            <div
                                v-for="(item, index) in chat"
                                :key="index"
                                :class="[
                                    'd-flex flex-row align-center my-3',
                                    item.from == 'user' ? 'justify-end' : null,
                                ]"
                            >
                                <div v-if="item.from == 'user'" class="d-flex">
                                    <span class="mr-1 message-txt">
                                        {{ item.msg }}
                                    </span>
                                    <v-avatar size="36">
                                        <v-img
                                            alt="App Logo"
                                            class="shrink"
                                            contain
                                            :src="
                                                require(`../../assets/profile.png`)
                                            "
                                            transition="scale-transition"
                                        ></v-img>
                                    </v-avatar>
                                </div>
                                <div v-else class="d-flex">
                                    <v-avatar size="36">
                                        <v-img
                                            alt="App Logo"
                                            class="shrink"
                                            contain
                                            :src="
                                                require(`@/assets/heroes/${hero}.svg`)
                                            "
                                            transition="scale-transition"
                                        ></v-img>
                                    </v-avatar>
                                    <span
                                        v-if="item.from != 'user'"
                                        class="ml-1 message-txt"
                                        >{{ item.msg }}
                                    </span>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="send-txt-action">
                <v-textarea
                    v-model="msg"
                    single-line
                    type="text"
                    rows="1"
                    auto-grow
                    light
                    filled
                    :placeholder="$t(`chatbox.askSomething`)"
                    hide-details
                >
                    <template v-slot:append>
                        <v-btn
                            light
                            icon
                            :ripple="false"
                            color="primary darken-2"
                            :disabled="disableSend"
                            @click="handleQuestion()"
                        >
                            <v-icon light size="20">
                                fa-solid fa-paper-plane
                            </v-icon>
                        </v-btn>
                    </template>
                </v-textarea>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>

<script>
import { mapState } from "vuex";
import { withAsync } from "@/helpers/withAsync.js";
import { askBot } from "@/api/chatbotApi.js";

import Hero from "@/components/Heroes/Hero.vue";
export default {
    name: "ChatBox",

    components: {
        Hero,
    },

    data() {
        return {
            msg: null,
            chat: [],
            disableSend: false,
        };
    },

    computed: {
        ...mapState({
            hero: (state) => state.auth.hero,
        }),
    },

    methods: {
        async handleQuestion() {
            this.chat.push({
                from: "user",
                msg: this.msg,
            });
            const payload = {
                message: this.msg,
            };
            this.msg = null;
            this.disableSend = true;
            const { response, error } = await withAsync(askBot, payload);
            this.msg = null;

            if (error) {
                console.log(error.response);
                return;
            }

            this.chat.push({
                from: "bot",
                msg: response.data.data.answer,
            });
            this.disableSend = false;
        },

        scrollToBottom() {
            window.setInterval(() => {
                let elem = document.querySelector(".list-chat");
                elem.scrollTop = elem.scrollHeight;
            }, 1000);
        },
    },
};
</script>

<style lang="scss" scoped>
.welcome-txt {
    font-size: 0.8em;
    font-weight: 400;
    line-height: 1;
    color: var(--v-text-darken1);
}

.send-txt-action {
    position: absolute;
    bottom: 4%;
    right: 0;
    left: 0;
}

.list-chat {
    height: 430px;
    overflow-y: auto;
}

.message-txt {
    background-color: #f0f0c3;
    border-radius: 10%;
    padding: 5px;
    font-weight: 500;
    font-size: 1em;
    color: var(--v-primary-darken3);
}
</style>
