<template>
    <v-container fluid class="login">
        <div class="overlay"></div>

        <div class="login__box shadow">
            <div class="login__header">
                <div class="my-2">
                    <h1>{{ $t("auth.registerTitle") }}</h1>
                </div>
            </div>

            <div class="login__form mt-4">
                <form @submit.prevent="register">
                    <text-input
                        :input-value.sync="name"
                        :in-valid.sync="inValidName"
                        :label="$t(`auth.name`)"
                    ></text-input>
                    <email-input
                        :email.sync="email"
                        :in-valid.sync="inValidEmail"
                        :label="$t(`auth.email`)"
                        :autofocus="false"
                    ></email-input>
                    <password-input
                        :password.sync="password"
                        :in-valid.sync="inValidPassword"
                        :label="$t(`auth.password`)"
                    ></password-input>
                    <confirm-password-input
                        :password="password"
                        :confirm-password.sync="confirmPassword"
                        :in-valid.sync="inValidConfirmPassword"
                        :label="$t(`auth.confirmPassword`)"
                    ></confirm-password-input>
                    <v-alert
                        v-model="showErrorAlert"
                        type="error"
                        dense
                        dismissible
                    >
                        {{ errorAlertText }}
                    </v-alert>
                    <p class="text-center">
                        <v-btn
                            tile
                            type="submit"
                            depressed
                            block
                            color="primary"
                            :disabled="validation"
                            class="button_round text-none text_btn"
                        >
                            {{ $t("auth.registerBtn") }}
                        </v-btn>
                    </p>
                    <v-row class="text-center mt-2 d-flex justify-center">
                        <v-col>
                            <v-btn
                                text
                                color="text"
                                plain
                                class="text_btn text-none"
                                :to="{ name: 'Login' }"
                            >
                                {{ $t("auth.haveAccount") }}
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn
                                text
                                plain
                                color="text"
                                class="text_btn text-none"
                                :to="{ name: 'Home' }"
                            >
                                {{ $t("auth.returnBack") }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </form>
                <div class="ps-relative mt-10">
                    <div class="ps-relative">
                        <div class="absolute__centered">
                            <v-avatar color="text lighten-2" size="36">
                                <span class="white--text">
                                    {{ $t(`auth.or`) }}
                                </span>
                            </v-avatar>
                        </div>
                        <div class="line"></div>
                    </div>
                    <v-row class="mt-10 d-flex justify-center">
                        <v-col class="text-center">
                            <login-with-facebook></login-with-facebook>
                        </v-col>
                        <v-col class="d-flex justify-center">
                            <login-with-google></login-with-google>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </div>
    </v-container>
</template>

<script>
import { withAsync } from "@/helpers/withAsync";
import { apiStatus } from "@/api/constants/apiStatus";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus";
import { registerUser } from "@/api/authApi.js";
import TextInput from "@/components/General/TextInput.vue";
import EmailInput from "@/components/General/EmailInput.vue";
import PasswordInput from "@/components/General/PasswordInput.vue";
import ConfirmPasswordInput from "@/components/General/ConfirmPasswordInput.vue";
import LoginWithFacebook from "./components/LoginWithFacebook.vue";
import LoginWithGoogle from "./components/LoginWithGoogle.vue";
export default {
    name: "Register",

    components: {
        TextInput,
        EmailInput,
        PasswordInput,
        ConfirmPasswordInput,
        LoginWithFacebook,
        LoginWithGoogle,
    },

    data() {
        return {
            name: null,
            email: null,
            password: null,
            confirmPassword: null,
            inValidName: true,
            inValidEmail: true,
            inValidPassword: true,
            inValidConfirmPassword: true,
            registerStatus: apiStatus.Idle,
            showErrorAlert: false,
            errorAlertText: "Κάτι πήγε στραβά...",
        };
    },

    computed: {
        ...apiStatusComputed("registerStatus"),

        validation() {
            if (this.inValidName) {
                return true;
            }
            if (this.inValidEmail) {
                return true;
            }
            if (this.inValidPassword) {
                return true;
            }
            if (this.inValidConfirmPassword) {
                return true;
            }
            return false;
        },
    },

    methods: {
        async register() {
            this.registerStatus = apiStatus.Pending;
            const payload = {
                name: this.name,
                email: this.email,
                password: this.password,
                password_confirm: this.confirmPassword,
            };

            const { respone, error } = await withAsync(registerUser, payload);

            if (error) {
                this.registerStatus = apiStatus.Error;
                this.showErrorAlert = true;
                this.errorAlertText = error.response.data.message;
                return;
            }

            this.registerStatus = apiStatus.Success;
            this.$router.push({ name: "Login" });
        },
    },

    created() {
        this.apiStatus = apiStatus;
    },
};
</script>

<style scoped>
.ps-relative {
    position: relative;
}
.line {
    border-top: 1px solid var(--v-text-lighten2);
    width: 100%;
}

.absolute__centered {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
</style>
