<template>
    <v-container fluid class="login">
        <div class="overlay"></div>

        <div class="login__box shadow">
            <div class="login__header">
                <div class="my-2">
                    <h1>{{ $t("auth.resetTitle") }}</h1>
                </div>
            </div>

            <div class="login__form mt-4">
                <form full-width @submit.prevent="handleReset()">
                    <password-input
                        :password.sync="password"
                        :in-valid.sync="inValidPassword"
                        :label="$t(`auth.password`)"
                    ></password-input>

                    <confirm-password-input
                        :password="password"
                        :confirm-password.sync="passwordConfirmation"
                        :in-valid.sync="inValidConfirmPassword"
                        :label="$t(`auth.confirmPassword`)"
                    ></confirm-password-input>

                    <p class="text-center">
                        <v-btn
                            tile
                            type="submit"
                            outlined
                            block
                            color="primary"
                            :disabled="validation"
                            class="button_round text-none text_btn"
                        >
                            {{ $t("auth.resetBtn") }}
                        </v-btn>
                    </p>

                    <p class="text-center mt-2 d-flex">
                        <v-btn
                            text
                            color="text"
                            plain
                            class="text_btn text-none"
                            :to="{ name: 'Register' }"
                        >
                            {{ $t("auth.signUpBtn") }}
                        </v-btn>

                        <v-btn
                            text
                            plain
                            color="text"
                            class="text_btn text-none"
                            :to="{ name: 'Home' }"
                        >
                            {{ $t("auth.returnBack") }}
                        </v-btn>
                    </p>
                </form>
            </div>
        </div>
    </v-container>
</template>

<script>
import { withAsync } from "@/helpers/withAsync";
import { apiStatus } from "@/api/constants/apiStatus";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus";
import { reset } from "@/api/authApi.js";
import { MessageSuccess, MessageError } from "@/api//services/toastNotification";
import PasswordInput from "@/components/General/PasswordInput.vue";
import ConfirmPasswordInput from "@/components/General/ConfirmPasswordInput.vue";
export default {
    name: "ResetPassword",

    components: {
        PasswordInput,
        ConfirmPasswordInput,
    },

    data() {
        return {
            password: null,
            passwordConfirmation: null,
            inValidPassword: true,
            inValidConfirmPassword: true,
            handleResetStatus: apiStatus.Idle,
        };
    },

    computed: {
        ...apiStatusComputed(""),

        validation() {
            if (this.inValidPassword) {
                return true;
            }
            if (this.inValidConfirmPassword) {
                return true;
            }
            return false;
        },
    },

    methods: {
        async handleReset() {
            this.handleResetStatus = apiStatus.Pending;

            const payload = {
                password: this.password,
                password_confirmation: this.passwordConfirmation,
                verification_code: this.$route.params.token,
            };

            const { response, error } = await withAsync(reset, payload);

            if (error) {
                this.handleResetStatus = apiStatus.Error;
                MessageError("Κάτι πήγε στραβά");
                return;
            }
            this.handleResetStatus = apiStatus.Success;
            MessageSuccess("Επιτυχής επαναφορά του κωδικού πρόσβασης.");
        },
    },

    created() {
        this.apiStatus = apiStatus;
    },
};
</script>

<style lang="scss" scoped>
.ps-relative {
    position: relative;
}
.line {
    border-top: 1px solid var(--v-text-lighten2);
    width: 100%;
}

.ps-absolute {
    position: absolute;
    right: 50%;
    top: -18px;
}
</style>
