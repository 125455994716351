import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
    /**
    * @todo implement app version.
        version:      process.env.VUE_APP_VERSION,
    */
    access_token: localStorage.getItem("lgf_token") || "",
    user: JSON.parse(localStorage.getItem("lgf_user_profile")) || {},
    hero: localStorage.getItem("lgf_hero") || "",
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
