require("./overrides.scss");
export default {
    theme: {
        themes: {
            light: {
                primary: "#a1d044",
                secondary: "#3F1708",
                accent: "#008485",
                info: "#ebf5cf",
                success: "#00b064",
                warning: "#ffcb6c",
                error: "#ff5b4d",
                text: "#2f4858",
                appBackground: "#f7fce8",
                garden: "#77A31F",
                constructions: "#4C4987",
                sensors: "#699895",
                nutrition: "#EE5B3F",
            },
        },
    },
};
