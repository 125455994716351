<template>
    <div ref="google_button">
        <div
            id="g_id_onload"
            data-client_id="816308890175-qg5q71ihql472huhm07jqnd4g9073nic.apps.googleusercontent.com"
            data-context="signin"
            data-ux_mode="popup"
            data-callback="handleGoogleLogin"
        ></div>

        <div
            class="g_id_signin"
            data-type="standard"
            data-shape="rectangular"
            data-theme="outline"
            data-text="signin_with"
            data-size="large"
            data-logo_alignment="left"
        ></div>
    </div>
</template>

<script>
import { withAsync } from "@/helpers/withAsync";
import { apiStatus } from "@/api/constants/apiStatus";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus";
import { logiWithGoogle } from "@/api/authApi.js";
import { apiObject } from "@/api/api.js";
export default {
    name: "LoginWithFacebook",

    methods: {
        handleGoogleLogin(response) {
            this.googleLogin(response.credential);
        },

        async googleLogin(jwt) {
            const payload = {
                google_token: jwt,
            };

            const { response, error } = await withAsync(
                logiWithGoogle,
                payload
            );

            if (error) {
                return;
            }

            this.$store.dispatch("auth/setLogin", response.data.data.token);
            this.$store.dispatch("auth/setUser", response.data.data.user);
            this.$store.dispatch(
                "auth/setHero",
                response.data.data.user.chatbot_character
            );

            apiObject.defaults.headers[
                "Authorization"
            ] = `Bearer ${response.data.data.token}`;

            this.loginStatus = apiStatus.Success;
            this.$router.push({ name: "Home" });
        },

        decodeJwtResponse(token) {
            var base64Url = token.split(".")[1];
            var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
            var jsonPayload = decodeURIComponent(
                atob(base64)
                    .split("")
                    .map(function(c) {
                        return (
                            "%" +
                            ("00" + c.charCodeAt(0).toString(16)).slice(-2)
                        );
                    })
                    .join("")
            );

            return JSON.parse(jsonPayload);
        },
    },

    mounted() {
        if (window.google) {
            window.google.accounts.id.initialize({
                client_id:
                    "816308890175-qg5q71ihql472huhm07jqnd4g9073nic.apps.googleusercontent.com",
                callback: this.handleGoogleLogin,
            });
            window.google.accounts.id.prompt();
            window.google.accounts.id.renderButton(this.$refs.google_button, {
                theme: "outline",
                size: "large",
            });
        }
    },
};
</script>
