export const CONFIG = {
    main_api: process.env.VUE_APP_API_URL,
    locale: process.env.VUE_APP_I18N_LOCALE,
    fallback_locale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
    card_heigth: 340,
    media_height: 286,
    min_card_heigth: 260,
    min_media_height: 200,
};

export default CONFIG;
