<template>
    <v-text-field
        v-model.trim="confirmPassword"
        required
        outlined
        color="primary"
        background-color="background darken-1"
        :type="passwordField"
        :append-icon="showPasswordIcon"
        :error-messages="confirmedPasswordErrors"
        @click:append="revealPassword()"
        @input="
            $v.confirmPassword.$touch();
            emit();
        "
        @blur="
            $v.confirmPassword.$touch();
            emit();
        "
    >
        <template v-slot:label>
            {{ label }}
        </template>
    </v-text-field>
</template>

<script>
import { required, sameAs, minLength } from "vuelidate/lib/validators";
export default {
    name: "ConfirmPassword",

    validations: {
        confirmPassword: {
            required,
            minLength: minLength(8),
            sameAsPassword: sameAs("password"),
        },
    },

    props: {
        label: {
            type: String,
            required: true,
        },
        password: {
            type: String,
            false: true,
            default: "",
        },
        value: {
            type: String,
            required: false,
            default: "",
        },
    },

    data() {
        return {
            confirmPassword: "",
            passwordField: "password",
        };
    },

    computed: {
        confirmedPasswordErrors() {
            const errors = [];
            if (!this.$v.confirmPassword.$dirty) return errors;
            !this.$v.confirmPassword.sameAsPassword &&
                errors.push(this.$i18n.t(`messages.error.sameAs`));
            !this.$v.confirmPassword.minLength &&
                errors.push(this.$i18n.t(`messages.error.minLenght`));
            return errors;
        },
        showPasswordIcon() {
            return this.passwordField == "password"
                ? "far fa-eye"
                : "far fa-eye-slash";
        },
    },

    methods: {
        emit() {
            this.$emit("update:confirm-password", this.confirmPassword);
            this.$emit("update:in-valid", this.$v.$invalid);
        },

        revealPassword() {
            this.passwordField == "password"
                ? (this.passwordField = "text")
                : (this.passwordField = "password");
        },
    },

    watch: {
        value(newVal) {
            if (newVal === "") this.$v.$reset();
            this.confirmPassword = newVal;
            this.emit();
        },
    },
};
</script>

<style lang="scss" scoped></style>
