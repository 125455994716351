<template>
    <component
        :is="componentSelected"
        :name="componentSelected"
        :animation-is="animationIs"
        :size="size"
    ></component>
</template>

<script>
import { mapState } from "vuex";
export default {
    name: "Hero",

    props: {
        animationIs: {
            required: false,
            type: String,
            default: "",
        },
        size: {
            required: false,
            type: Number,
            default: () => {
                return 90;
            },
        },
        explicitHero: {
            required: false,
            type: String,
            default: null,
        },
    },

    components: {
        Tomato: () => import(`./Tomato.vue`),
        Carrot: () => import(`./Carrot.vue`),
        Eggplant: () => import(`./Eggplant.vue`),
        Lemon: () => import(`./Lemon.vue`),
        Pepper: () => import(`./Pepper.vue`),
        Pineapple: () => import(`./Pineapple.vue`),
        Pomegranate: () => import(`./Pomegranate.vue`),
        Potato: () => import(`./Potato.vue`),
    },

    computed: {
        ...mapState({
            hero: (state) => state.auth.hero,
        }),

        componentSelected() {
            let switchValue;
            if (this.explicitHero) switchValue = this.explicitHero;
            else switchValue = this.hero;
            switch (switchValue) {
                case "tomato":
                    return "tomato";
                case "carrot":
                    return "carrot";
                case "lemon":
                    return "lemon";
                case "eggplant":
                    return "eggplant";
                case "pepper":
                    return "pepper";
                case "pineapple":
                    return "pineapple";
                case "pomegranate":
                    return "pomegranate";
                case "potato":
                    return "potato";
            }
            return "tomato";
        },
    },
};
</script>
