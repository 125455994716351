<template>
    <v-container>
        <v-row>
            <v-col class="col-12">
                <img src="@/assets/logo/logo.svg" />
                <h1 class="text-center">
                    Οδηγός Χρήσης #LittleGreenFarmers
                </h1>
            </v-col>
            <v-col class="col-12">
                <p>
                    Σε περίπτωση που έχετε τυχόν απορίες ή δε γνωρίζετε πως να
                    χρησιμοποιήσετε την εφαρμογή #LittleGreenFarmers, έχουμε ετοιμάσει
                    έναν Οδηγό Χρήσης, ο οποίος θα διευκολύνει την περιήγησή σας. Μπορείτε να τον κατεβάσετε και να μάθετε πως μπορείτε να
                    αξιοποιήσετε όλες τις λειτουργίες του #LittleGreenFarmers.
                </p>
            </v-col>
        </v-row>
        <v-row>
          <v-col class="col-12">
            <p>Κατεβάστε τον οδηγό 
              <a href="/Οδηγός Χρήσης.pdf" download>πατώντας εδώ</a>
            </p>
          </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "UserInstructions",
};
</script>
