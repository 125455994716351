<template>
    <v-btn
        depressed
        dense
        color="#4267B2"
        width="150"
        @click="logInWithFacebook()"
    >
        <v-icon size="16" class="mr-2" color="white">
            fa-brands fa-facebook-f
        </v-icon>
        <span class="white--text">Facebook</span>
    </v-btn>
</template>

<script>
import { withAsync } from "@/helpers/withAsync";
import { apiStatus } from "@/api/constants/apiStatus";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus";
import { logiWithFb } from "@/api/authApi.js";
import { apiObject } from "@/api/api.js";
export default {
    name: "LoginWithFacebook",

    methods: {
        async logInWithFacebook() {
            let fbToken;
            if (window.FB) {
                window.FB.login(function(response) {
                    if (response.authResponse) {
                        fbToken = response.authResponse.accessToken;
                    } else {
                        alert(
                            "User cancelled login or did not fully authorize."
                        );
                    }
                });
                this.loginToApp(fbToken);
            }
            return false;
        },
        async initFacebook() {
            window.fbAsyncInit = function() {
                window.FB.init({
                    appId: "5499899460040626", //You will need to change this
                    cookie: true, // This is important, it's not enabled by default
                    xfbml: true,
                    version: "v13.0",
                });
                window.FB.getLoginStatus(function(response) {});
            };
        },
        async loadFacebookSDK(d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        },

        async loginToApp(fbToken) {
            const payload = {
                fb_token: fbToken,
            };

            const { response, error } = await withAsync(logiWithFb, payload);

            if (error) {
                return;
            }

            this.$store.dispatch("auth/setLogin", response.data.data.token);
            this.$store.dispatch("auth/setUser", response.data.data.user);
            this.$store.dispatch(
                "auth/setHero",
                response.data.data.user.chatbot_character
            );

            apiObject.defaults.headers[
                "Authorization"
            ] = `Bearer ${response.data.data.token}`;

            this.loginStatus = apiStatus.Success;
            this.$router.push({ name: "Home" });
        },
    },

    mounted() {
        this.loadFacebookSDK(document, "script", "facebook-jssdk");
        this.initFacebook();
    },
};
</script>
