<template>
    <div>
        <v-navigation-drawer
            v-if="mobile"
            v-model="drawer"
            absolute
            color="primary darken2"
            temporary
        >
            <nav-items> </nav-items>
        </v-navigation-drawer>
        <v-app-bar
            app
            color="primary"
            flat
            height="80"
            extended
            extension-height="40"
        >
            <v-container>
                <v-row class="pt-2">
                    <v-col>
                        <v-row class="flex-wrap-reverse">
                            <v-col cols="12" sm="6" class="pt-1">
                                <app-logo v-if="!mobile"></app-logo>
                                <v-app-bar-nav-icon
                                    v-if="mobile"
                                    @click.stop="drawer = !drawer"
                                ></v-app-bar-nav-icon>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                class="d-flex justify-end align-start pt-1"
                            >
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col
                        cols="6"
                        align-self="center"
                        class="pt-0 d-flex justify-end"
                    >
                        <profile-menu :is-logged-in="isLoggedIn"></profile-menu>
                    </v-col>
                </v-row>
            </v-container>

            <template v-if="!mobile" v-slot:extension>
                <v-container>
                    <v-row>
                        <v-col cols="12" md="6" class="pt-0 align-self-center">
                            <nav-items></nav-items>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </v-app-bar>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getDevice } from "@/helpers/generalHelpers.js";

import AppLogo from "./components/AppLogo.vue";
import EspaBanner from "@/assets/banner_espa.png";
import AppMobileLogo from "./components/AppMobileLogo.vue";
import NavItems from "./components/NavItems.vue";
import ProfileMenu from "./components/ProfileMenu.vue";
export default {
    name: "NavBar",

    components: {
        AppLogo,
        AppMobileLogo,
        NavItems,
        ProfileMenu,
    },

    data() {
        return {
            EspaBanner,
            mobile: false,
            drawer: false,
        };
    },

    computed: {
        ...mapGetters({
            isLoggedIn: "auth/isLoggedIn",
        }),
    },

    methods: {
        isMobile() {
            this.mobile = getDevice();
        },
    },
    mounted() {
        window.addEventListener("resize", this.isMobile);
        this.isMobile();
    },

    beforeDestroy() {
        window.removeEventListener("resize", this.isMobile);
    },
};
</script>

<style lang="scss" scoped>
.mobile__drawer {
    transition: all 0.5s ease-in-out;
    position: fixed;
    z-index: 500;
    min-width: 100vw;
    min-height: 100vh;
    background-color: var(--v-primary-darken2);
}
</style>
