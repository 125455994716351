import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Layout from "@/layouts/Layout.vue";
import NavBar from "@/layouts/NavBar.vue";
import Login from "../views/Auth/Login";
import Register from "../views/Auth/Register";
import ResetPassword from "../views/Auth/ResetPassword.vue";
import TermsConditions from "../views/TermsConditions.vue";
import UserInstructions from "../views/UserInstructions.vue";
import NewProjectBtn from "@/layouts/components/NewProjectBtn";

Vue.use(VueRouter);

const ifAuthenticated = (to, from, next) => {
    if (localStorage.getItem("lgf_token")) {
        next("/");
        return;
    }
    next();
};

const routes = [
    {
        path: "/login",
        name: "Login",
        component: Login,
        beforeEnter: ifAuthenticated,
    },
    {
        path: "/reset-password/:token",
        name: "ResetPassword",
        component: ResetPassword,
        beforeEnter: ifAuthenticated,
    },
    {
        path: "/register",
        name: "Register",
        component: Register,
        beforeEnter: ifAuthenticated,
    },

    {
        path: "/terms",
        name: "TermsConditions",
        component: TermsConditions,
    },
    {
        path: "/",
        name: "Landing",
        redirect: { name: "Garden" },
        component: Layout,
        children: [
            {
                path: "/home",
                name: "Home",
                redirect: { name: "Garden" },
                components: {
                    topbar: NavBar,
                    newProject: NewProjectBtn,
                    main: () =>
                        import(
                            /* webpackChunkName: "Home" */ "@/views/Home.vue"
                        ),
                },
                children: [
                    {
                        path: "garden",
                        name: "Garden",
                        component: () =>
                            import(
                                /* webpackChunkName: "Home" */ "@/views/Home/Garden.vue"
                            ),
                    },
                    {
                        path: "constructions",
                        name: "Constructions",
                        component: () =>
                            import(
                                /* webpackChunkName: "Home" */ "@/views/Home/Constructions.vue"
                            ),
                    },
                    {
                        path: "sensors",
                        name: "Sensors",
                        component: () =>
                            import(
                                /* webpackChunkName: "Home" */ "@/views/Home/Sensors.vue"
                            ),
                    },
                    {
                        path: "nutrition",
                        name: "Nutrition",
                        component: () =>
                            import(
                                /* webpackChunkName: "Home" */ "@/views/Home/Nutrition.vue"
                            ),
                    },
                ],
            },

            {
                path: "/about",
                name: "About",
                components: {
                    topbar: NavBar,
                    newProject: NewProjectBtn,
                    main: () =>
                        import(
                            /* webpackChunkName: "About" */ "@/views/About.vue"
                        ),
                },
            },

            {
                path: "/privacy-policy",
                name: "PrivacyPolicy",
                components: {
                    topbar: NavBar,
                    newProject: NewProjectBtn,
                    main: () =>
                        import(
                            /* webpackChunkName: "PrivacyPolicy" */ "@/views/PrivacyPolicy.vue"
                        ),
                },
            },

            {
                path: "/terms-of-use",
                name: "TermsOfUse",
                components: {
                    topbar: NavBar,
                    newProject: NewProjectBtn,
                    main: () =>
                        import(
                            /* webpackChunkName: "TermsOfUse" */ "@/views/TermsOfUse.vue"
                        ),
                },
            },
            {
                path: "/user-instructions",
                name: "UserInstructions",
                components: {
                    topbar: NavBar,
                    newProject: NewProjectBtn,
                    main: () =>
                        import(
                            /* webpackChunkName: "UserInstructions" */ "@/views/UserInstructions.vue"
                        ),
                },
            },

            {
                path: "/garden/:id",
                name: "GardenInfo",
                components: {
                    topbar: NavBar,
                    newProject: NewProjectBtn,
                    main: () =>
                        import(
                            /* webpackChunkName: "ConstructionsInfo" */ "@/views/Garden/GardenInfo.vue"
                        ),
                },
            },

            {
                path: "/constructions/:id",
                name: "ConstructionInfo",
                components: {
                    topbar: NavBar,
                    newProject: NewProjectBtn,
                    main: () =>
                        import(
                            /* webpackChunkName: "ConstructionsInfo" */ "@/views/Constructions/ConstructionInfo.vue"
                        ),
                },
            },

            {
                path: "/recipe/:id",
                name: "RecipeInfo",
                components: {
                    topbar: NavBar,
                    newProject: NewProjectBtn,
                    main: () =>
                        import(
                            /* webpackChunkName: "RecipeInfo" */ "@/views/Nutrition/RecipeInfo.vue"
                        ),
                },
            },
            {
                path: "/advice/:id",
                name: "AdviceInfo",
                components: {
                    topbar: NavBar,
                    newProject: NewProjectBtn,
                    main: () =>
                        import(
                            /* webpackChunkName: "AdviceInfo" */ "@/views/Nutrition/AdviceInfo.vue"
                        ),
                },
            },

            {
                path: "/favourites",
                name: "Favourites",
                components: {
                    topbar: NavBar,
                    newProject: NewProjectBtn,
                    main: () =>
                        import(
                            /* webpackChunkName: "Favourites" */ "@/views/Favourites.vue"
                        ),
                },
                meta: { authentication: true },
            },
            {
                path: "/library",
                name: "Library",
                components: {
                    topbar: NavBar,
                    newProject: NewProjectBtn,
                    main: () =>
                        import(
                            /* webpackChunkName: "Library" */ "@/views/Library.vue"
                        ),
                },
                children: [
                    {
                        path: ":id",
                        name: "LibraryInfo",
                        component: () =>
                            import(
                                /* webpackChunkName: "LibraryInfo" */ "@/views/Library/LibraryInfo.vue"
                            ),
                    },
                ],
            },
            {
                path: "/my-projects",
                name: "MyProjects",
                components: {
                    topbar: NavBar,
                    newProject: NewProjectBtn,
                    main: () =>
                        import(
                            /* webpackChunkName: "MyProjects" */ "@/views/MyProjects.vue"
                        ),
                },
                children: [
                    {
                        path: ":id",
                        name: "MyProjectInfo",
                        component: () =>
                            import(
                                /* webpackChunkName: "MyProjectInfo" */ "@/views/MyProjects/MyProjectInfo.vue"
                            ),
                    },
                ],
            },
            {
                path: "/new-project",
                name: "NewProject",
                components: {
                    topbar: NavBar,
                    main: () =>
                        import(
                            /* webpackChunkName: "NewProject" */ "@/views/Project/NewProject.vue"
                        ),
                },
            },
            {
                path: "/select-project-type",
                name: "SelectProjectType",
                components: {
                    topbar: NavBar,
                    main: () =>
                        import(
                            /* webpackChunkName: "SelectProjectType" */ "@/views/Project/SelectProjectType.vue"
                        ),
                },
            },
            {
                path: "/project-builder/:type",
                name: "ProjectBuilder",
                components: {
                    topbar: NavBar,
                    main: () =>
                        import(
                            /* webpackChunkName: "ProjectBuilder" */ "@/views/Project/ProjectBuilder.vue"
                        ),
                },
            },
            {
                path: "/edit-project/:id",
                name: "EditProject",
                components: {
                    topbar: NavBar,
                    main: () =>
                        import(
                            /* webpackChunkName: "EditProject" */ "@/views/Project/EditProject.vue"
                        ),
                },
            },
            {
                path: "/edit-recipe/:id",
                name: "EditRecipe",
                components: {
                    topbar: NavBar,
                    main: () =>
                        import(
                            /* webpackChunkName: "EditRecipe" */ "@/views/Nutrition/EditRecipe.vue"
                        ),
                },
            },
            {
                path: "/edit-advice/:id",
                name: "EditAdvice",
                components: {
                    topbar: NavBar,
                    main: () =>
                        import(
                            /* webpackChunkName: "EditAdvice" */ "@/views/Nutrition/EditAdvice.vue"
                        ),
                },
            },
            {
                path: "/edit-library/:id",
                name: "EditLibrary",
                components: {
                    topbar: NavBar,
                    main: () =>
                        import(
                            /* webpackChunkName: "EditLibrary" */ "@/views/Library/EditLibrary.vue"
                        ),
                },
            },
            {
                path: "/profile",
                name: "Profile",
                components: {
                    topbar: NavBar,
                    main: () =>
                        import(
                            /* webpackChunkName: "Profile" */ "@/views/Profile.vue"
                        ),
                },
            },
        ],
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to) {
        let top = 0;

        if (to.hash) {
            const element = document.querySelector(to.hash);
            if (element) {
                top = element.offsetTop;
            }
        }

        return window.scrollTo({ top: top, behavior: "smooth" });
    },
});

// router.beforeEach((to, from, next) => {
//     if (to.matched.some((record) => record.meta.authentication)) {
//         if (store.getters["auth/isLoggedIn"]) {
//             next();
//         } else {
//             return next("/login");
//         }
//     }
//     next();
// });

export default router;
