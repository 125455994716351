<template>
    <v-container fluid class="login">
        <div class="overlay"></div>

        <div class="login__box shadow">
            <div class="login__header">
                <div class="my-2">
                    <h1>{{ $t("auth.loginTitle") }}</h1>
                </div>
            </div>

            <div class="login__form mt-4">
                <form full-width @submit.prevent="login()">
                    <email-input
                        class="mb-4"
                        :email.sync="email"
                        :in-valid.sync="inValidEmail"
                        :autofocus="false"
                        :label="$t(`auth.email`)"
                    ></email-input>

                    <password-input
                        :password.sync="password"
                        :in-valid.sync="inValidPassword"
                        :label="$t(`auth.password`)"
                    ></password-input>

                    <v-alert
                        v-model="showErrorAlert"
                        type="error"
                        dense
                        dismissible
                    >
                        {{ errorAlertText }}
                    </v-alert>
                    <p class="text-center">
                        <v-btn
                            tile
                            type="submit"
                            depressed
                            block
                            color="primary"
                            class="button_round text-none text_btn"
                        >
                            {{ $t("auth.signInBtn") }}
                        </v-btn>
                    </p>

                    <v-row class="text-center mt-2 d-flex justify-center">
                        <v-col>
                            <v-btn
                                text
                                color="text"
                                plain
                                class="text_btn text-none"
                                :to="{ name: 'Register' }"
                            >
                                {{ $t("auth.signUpBtn") }}
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn
                                text
                                plain
                                color="text"
                                class="text_btn text-none"
                                :to="{ name: 'Home' }"
                            >
                                {{ $t("auth.returnBack") }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </form>
                <div class="ps-relative mt-10">
                    <div class="ps-relative">
                        <div class="absolute__centered">
                            <v-avatar color="text lighten-2" size="36">
                                <span class="white--text">
                                    {{ $t(`auth.or`) }}
                                </span>
                            </v-avatar>
                        </div>
                        <div class="line"></div>
                    </div>
                    <v-row class="mt-10 d-flex justify-center">
                        <v-col class="text-center">
                            <login-with-facebook></login-with-facebook>
                        </v-col>
                        <v-col class="d-flex justify-center">
                            <login-with-google></login-with-google>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </div>
    </v-container>
</template>

<script>
import { withAsync } from "@/helpers/withAsync";
import { apiStatus } from "@/api/constants/apiStatus";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus";
import { loginUser } from "@/api/authApi.js";
import { apiObject } from "@/api/api.js";
import { required, email, minLength } from "vuelidate/lib/validators";
import EmailInput from "@/components/General/EmailInput.vue";
import PasswordInput from "@/components/General/PasswordInput.vue";
import LoginWithFacebook from "./components/LoginWithFacebook.vue";
import LoginWithGoogle from "./components/LoginWithGoogle.vue";
export default {
    name: "Login",

    validations: {
        email: {
            required,
            email,
        },
        password: {
            required,
            minLength: minLength(8),
        },
    },

    components: {
        EmailInput,
        PasswordInput,
        LoginWithFacebook,
        LoginWithGoogle,
    },

    data() {
        return {
            email: null,
            password: null,
            inValidEmail: true,
            inValidPassword: true,
            loginStatus: apiStatus.idle,

            showErrorAlert: false,
            errorAlertText: "Κάτι πήγε στραβά...",
        };
    },

    computed: {
        ...apiStatusComputed("loginStatus"),

        validation() {
            if (this.inValidEmail) {
                return true;
            }
            if (this.inValidPassword) {
                return true;
            }
            return false;
        },
    },

    methods: {
        async login() {
            this.loginStatus = apiStatus.Pending;
            const payload = {
                email: this.email,
                password: this.password,
            };

            const { response, error } = await withAsync(loginUser, payload);

            if (error) {
                this.loginStatus = apiStatus.Error;
                this.showErrorAlert = true;
                this.errorAlertText = error.response.data.message;
                return;
            }

            this.$store.dispatch("auth/setLogin", response.data.data.token);
            this.$store.dispatch("auth/setUser", response.data.data.user);
            this.$store.dispatch(
                "auth/setHero",
                response.data.data.user.chatbot_character
            );
            this.$store.dispatch(
                "auth/setHero",
                response.data.data.user.chatbot_character
            );

            apiObject.defaults.headers[
                "Authorization"
            ] = `Bearer ${response.data.data.token}`;

            this.loginStatus = apiStatus.Success;
            this.$router.push({ name: "Home" });
        },
    },

    created() {
        this.apiStatus = apiStatus;
    },
};
</script>

<style lang="scss" scoped>
.ps-relative {
    position: relative;
}
.line {
    border-top: 1px solid var(--v-text-lighten2);
    width: 100%;
}

.absolute__centered {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
</style>
