<template>
    <v-fab-transition>
        <v-btn
            color="pink"
            light
            fixed
            bottom
            right
            fab
            style="z-index: 100; right: 6%; bottom: 6%;"
        >
            <v-img
                alt="App Logo"
                class="shrink"
                contain
                :src="require(`@/assets/heroes/${selectedHero.type}.svg`)"
                width="100"
                height="90"
                transition="scale-transition"
            ></v-img>
        </v-btn>
    </v-fab-transition>
</template>

<script>
export default {
    name: "ChatBoxHero",

    data() {
        return {
            selectedHero: {
                type: "tomato",
            },
        };
    },

    computed: {
        selected() {
            if (this.selectedHero) {
                return this.selectedHero;
            }

            return { type: "tomato" };
        },
    },
};
</script>
