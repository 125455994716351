<template>
    <v-row app style="width: 100%" class="mx-0">
        <v-col class="footer">
            <v-row justify="center" class="pt-10 pb-15">
                <div class="d-flex flex-column align-center">
                    <img
                        :src="EspaBanner"
                        style="object-fit: contain; max-width: 650px"
                        alt="Espa banner"
                        transition="scale-transition"
                        class="espa elavation-12 rounded"
                    />
                    <v-spacer class="py-4"></v-spacer>
                    <div style="width: 70%" class="text-center">
                        <span>
                            {{ $t("Footer.espaBanner") }}
                        </span>
                    </div>
                </div>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" sm="4" lg="2" class="d-flex flex-column">
                    <img
                        :src="require('../assets/footer/appstore.svg')"
                        height="80"
                        contain
                    />
                    <div class="d-flex justify-center align-center mt-2">
                        <img
                            :src="require('../assets/footer/appleicon.svg')"
                            height="40"
                            contain
                        />
                        <span>
                            App Store
                        </span>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="4"
                    lg="2"
                    align-self="center"
                    class="text-center text-caption order-first order-sm-0"
                >
                    {{ $t("Footer.downloadFrom") }}
                </v-col>
                <v-col cols="12" sm="4" lg="2" class="d-flex flex-column ">
                    <img
                        height="80"
                        contain
                        :src="require('../assets/footer/gplay.svg')"
                    />
                    <div class="d-flex justify-center align-center mt-2">
                        <img
                            :src="require('../assets/footer/googleicon.svg')"
                            height="40"
                            contain
                        />
                        <span>
                            Google Play
                        </span>
                    </div>
                </v-col>
            </v-row>
            <v-row class="bottom__container">
                <v-col align-self="end" class="pb-5">
                    <v-row class="bottom-content" align="center">
                        <v-col
                            cols="12"
                            md="8"
                            lg="9"
                            class="d-flex align-center flex-wrap pb-0"
                        >
                            <app-logo></app-logo>
                            <span
                                v-for="(item, idx) in menu"
                                :key="idx"
                                class="px-4"
                            >
                                <router-link :to="item.path">
                                    {{ $t(`Footer.${item.label}`) }}
                                </router-link>
                            </span>
                        </v-col>
                        <v-col cols="12" md="5" lg="3" class="justify-end">
                            <span class="px-2">
                                <a
                                    href="https://www.youtube.com/@littlegreenfarmers9043"
                                    target="_blank"
                                >
                                    <i class="fa-brands fa-youtube fa-lg"></i>
                                </a>
                            </span>
                            <span class="px-2">
                                <a
                                    href="https://www.facebook.com/LittleGreenFarmers"
                                    target="_blank"
                                >
                                    <i
                                        class="fa-brands fa-facebook-f fa-lg"
                                    ></i>
                                </a>
                            </span>
                            <span class="px-2">
                                <a
                                    href="https://www.instagram.com/littlegreenfarmers/"
                                    target="_blank"
                                >
                                    <i class="fa-brands fa-instagram fa-lg"></i>
                                </a>
                            </span>
                        </v-col>

                        <!-- <v-col cols="12" md="4">
                            <img
                                :src="EspaBanner"
                                style="object-fit: contain; max-width: 350px"
                                alt="Espa banner"
                                transition="scale-transition"
                                class="espa"
                            />
                        </v-col> -->
                    </v-row>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import AppLogo from "../layouts/components/AppLogo.vue";
import EspaBanner from "@/assets/footer/eu.png";
export default {
    name: "TheFooter",
    components: { AppLogo },

    data() {
        return {
            EspaBanner,
            menu: [
                {
                    name: "LGF",
                    path: "/about",
                    label: "lgf",
                },
                {
                    name: "Privacy",
                    path: "/privacy-policy",
                    label: "privacy",
                },
                {
                    name: "TermsOfUse",
                    path: "/terms-of-use",
                    label: "termsOfUse",
                },
                {
                    name: "UserInstructions",
                    path: "/user-instructions",
                    label: "userInstructions",
                },
            ],
        };
    },
};
</script>

<style scoped>
.footer {
    background-color: var(--v-primary-base);
}

.footer_top__container {
    padding: 64px 0;
}

.app-links {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    min-height: 200px;
}

.bottom__container {
    background: url("../assets/footer/grass.svg") no-repeat;
    background-size: 100%;
    min-height: 400px;
}

@media (min-width: 768px) {
    .bottom__container {
        min-height: 200px;
    }
}

@media (max-width: 374px) {
    .espa {
        width: 250px;
    }
}
@media (max-width: 860px) {
    .espa {
        width: 350px;
    }
}
</style>
