import { api, apiObject } from "./api";

const URLS = {
    login: "auth/login",
    logout: "auth/logout",
    register: "auth/register",
    fb: "facebook/authenticate",
    google: "google/authenticate",
    reset: "/auth/password-reset",
};

export const loginUser = (payload) => {
    return api.post(URLS.login, payload);
};

export const logoutUser = () => {
    const response = api.post(URLS.logout);
    delete apiObject.defaults.headers["Authorization"];
    return response;
};

export const registerUser = (payload) => {
    return api.post(URLS.register, payload);
};

export const logiWithFb = (payload) => {
    return api.post(URLS.fb, payload);
};

export const logiWithGoogle = (payload) => {
    return api.post(URLS.google, payload);
};

export const reset = (payload) => {
    return api.post(URLS.reset, payload);
};
