import { api } from "./api";

const URLS = {
    chatbot: "chatbot",
    update: "user/chatbot/update/",
    bot: "wit-ai",
};

export const getHeroes = () => {
    return api.get(URLS.chatbot);
};

export const setHero = (payload, url) => {
    return api.patch(url, payload);
};

export const askBot = (payload) => {
    return api.post(URLS.bot, payload);
};
