const setLogin = (state, data) => {
    state.access_token = data;
};

const setUser = (state, user) => {
    state.user = user;
};

const logout = (state) => {
    state.access_token = "";
    state.user = {};
};

const setHero = (state, data) => {
    state.hero = data;
};

export default {
    setLogin,
    setUser,
    logout,
    setHero,
};
