<template>
    <router-link :to="{ name: 'Home' }">
        <v-img
            alt="App Logo"
            class="shrink mr-2"
            contain
            :src="logo"
            transition="scale-transition"
            width="300"
        ></v-img>
    </router-link>
</template>

<script>
import logo from "../../assets/logo/logo_hor.svg";
export default {
    name: "AppLogo",

    data() {
        return {
            logo,
        };
    },
};
</script>
