import Vue from "vue";
import VueI18n from "vue-i18n";
import el from "../locales/el.json"
import { CONFIG } from "../common/config"

Vue.use(VueI18n);

export default new VueI18n({
  locale: CONFIG.locale,
  fallbackLocale: CONFIG.fallback_locale,
  messages: {
    el
  },
  silentTranslationWarn: true
});
