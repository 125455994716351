import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify/index";
import Vuelidate from "vuelidate";
import i18n from "./plugins/i18n";
// import GAuth from "vue-google-oauth2";

import "@fortawesome/fontawesome-free/css/all.css";

// const gauthOption = {
//     clientId:
//         "215909254701-dqtsb8h7444fkv119lq3btl7oqicehl4.apps.googleusercontent.com",
//     scope: "email",
//     prompt: "select_account",
// };
// Vue.use(GAuth, gauthOption);

Vue.use(Vuelidate);

Vue.config.productionTip = false;

new Vue({
    name: "LGF",
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
}).$mount("#app");
