<template>
    <v-avatar :size="size">
        <v-img
            alt="App Logo"
            class="shrink"
            contain
            :src="computedAvatar"
            transition="scale-transition"
        ></v-img>
    </v-avatar>
</template>

<script>
import { mapState } from "vuex";
import { blobToBase64 } from "@/helpers/generalHelpers.js";

const baseAvatar = require("../../assets/profile.png");

export default {
    name: "Avatar",

    props: {
        size: {
            required: true,
            type: String,
        },
        file: {
            required: false,
            type: File,
            default: null,
        },
    },
    data() {
        return {
            baseAvatar,
            base64: null,
        };
    },

    computed: {
        ...mapState({
            user: (state) => state.auth.user,
        }),
        computedAvatar() {
            if (this.base64) {
                return this.base64;
            }
            if (this.user.image_url) return this.user.image_url;
            else return this.baseAvatar;
        },
    },

    watch: {
        file(newVal) {
            if (!newVal) {
                this.base64 = null;
                return;
            }

            blobToBase64(newVal).then((res) => {
                this.base64 = res;
            });
        },
    },
};
</script>
