<template>
    <v-text-field
        v-model.trim="email"
        required
        outlined
        :autofocus="autofocus"
        color="primary"
        :label="label"
        :error-messages="emailErrors"
        @input="
            $v.email.$touch();
            emitEmail();
        "
        @blur="
            $v.email.$touch();
            emitEmail();
        "
    >
    </v-text-field>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
export default {
    name: "EmailInput",

    validations: {
        email: {
            required,
            email,
        },
    },

    props: {
        currentEmail: {
            type: String,
            required: false,
            default: "",
        },
        label: {
            required: true,
            type: String,
        },
        autofocus: {
            required: true,
            type: Boolean,
            default: true,
        },

        value: {
            required: false,
            type: String,
            default: "",
        },
    },

    data() {
        return {
            email: "",
        };
    },

    computed: {
        emailErrors() {
            const errors = [];
            if (!this.$v.email.$dirty) return errors;
            !this.$v.email.required &&
                errors.push(this.$i18n.t(`messages.error.required`));
            !this.$v.email.email &&
                errors.push(this.$i18n.t(`messages.error.validEmail`));
            return errors;
        },
    },

    methods: {
        emitEmail() {
            this.$emit("update:email", this.email);
            this.$emit("update:in-valid", this.$v.$invalid);
        },
    },

    watch: {
        value(newVal) {
            if (newVal === "") this.$v.$reset();
            this.email = newVal;
            this.emit();
        },
    },
    created() {
        if (this.currentEmail) {
            this.email = this.currentEmail;
        }
    },
};
</script>

<style lang="scss" scoped></style>
